import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import authenticationApi from "../api/authentication";
import Alert from "../components/Alerts/Alert";
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [token, setToken] = useState("");
  const [step, setStep] = useState(1);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromQuery = queryParams.get("token");

    if (tokenFromQuery) {
      setToken(tokenFromQuery);
      setStep(3);
    }
  }, [location.search]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    try {
      await authenticationApi.forgotPassword({ email });
      setAlertData({
        message: "A password reset link has been sent to your email.",
        status: "success",
      });
      setStep(2);
    } catch (error) {
      setAlertData({
        message: "Error sending reset email. Please try again.",
        status: "error",
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!passwordsMatch) {
      setAlertData({ message: "Passwords do not match.", status: "error" });
      return;
    }

    if (!token) {
      setAlertData({
        message: "Token is missing. Please try again.",
        status: "error",
      });
      return;
    }

    try {
      const response = await authenticationApi.resetPassword({
        email,
        password,
        token,
      });
      console.log("response", response)
      if (response.status === 200)
      {
        setAlertData({
          message: "Password reset successful!",
          status: "success",
        });
        navigate("/");
      }

    } catch (error) {
      setAlertData({
        message: "Error resetting password. Please try again.",
        status: "error",
      });
    }
  };

  const handleChange = (e, field) => {
    const value = e.target.value;

    if (field === "email") {
      setEmail(value);
    } else if (field === "password") {
      setPassword(value);
    } else if (field === "confirmPassword") {
      setConfirmPassword(value);
    }

    setPasswordsMatch(
      value === (field === "password" ? confirmPassword : password)
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {alertData && (
        <Alert
          message={alertData.message}
          status={alertData.status}
          onClose={() => setAlertData(null)}
        />
      )}

      {step === 1 ? (
        <section className="navigation">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                  Confirm your email
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handleEmailSubmit}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      className="border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={email}
                      onChange={(e) => handleChange(e, "email")}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="primaryBtn w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Send Reset Link
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : step === 2 ? (
        <section className="navigation">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                  Reset Password Sent
                </h1>
                <p>
                 Please check your emails for reset link
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : step === 3 ? (
        <section className="navigation">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                  Reset your password
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handlePasswordSubmit}
                >
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        className="border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={password}
                        onChange={(e) => handleChange(e, "password")}
                        required
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 px-3 py-2 focus:outline-none"
                      >
                        {showPassword ? "👁️" : "👁️‍🗨️"}
                      </button>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className="block mb-2 text-sm font-medium"
                    >
                      Confirm Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm your password"
                      className={`border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        !passwordsMatch ? "border-red-500" : ""
                      }`}
                      value={confirmPassword}
                      onChange={(e) => handleChange(e, "confirmPassword")}
                      required
                    />
                    {!passwordsMatch && (
                      <p className="text-sm text-red-500">
                        Passwords do not match.
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="primaryBtn w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="navigation">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                  Error
                </h1>
                <p>Something went wrong. Please try again later.</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ForgotPassword;
