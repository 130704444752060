import React, { useState } from 'react';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the item if it's already open
    } else {
      setOpenIndex(index); // Open the clicked item
    }
  };

  const faqItems = [
    {
      question: 'How does the app work?',
      answer:
        'Simply download, choose a tour, and enjoy a live guide broadcast to your phone, providing real-time insights and navigation.',
    },
    {
      question: 'Can I interact with the live guide?',
      answer:
        'Yes, our interactive platform allows users to ask questions and receive instant responses from the live guide, enhancing engagement during the tour.',
    },
    {
      question: 'Is it available offline?',
      answer:
        "No, but fear not! If you're without internet, our guide can assist you in connecting, ensuring you don\'t miss a moment of the adventure.",
    },
    {
      question: 'Is the app easy for tourists and guides to use?',
      answer:
        'Yes, it\'s incredibly easy! The mobile application is designed for seamless navigation, ensuring a straightforward experience for both tourists and guides. Download, log in, and enjoy the convenience of real-time guide narration at your fingertips.',
    },
    {
      question: 'What are the pricing options for the app?',
      answer:
        'Curious about the costs? Get all the details and experience the app\'s features firsthand with a demo session today!',
    },
  ];

  return (
    <section id="faq-section" className="mt-12">
      <div className="pb-40 xl:pb-[220px]">
        <div className="global-container">
          <div className="mx-auto mb-10 text-center md:mb-16 md:max-w-xl lg:mb-20 lg:max-w-3xl xl:max-w-[856px]">
            <h2 className="font-clashDisplay text-4xl font-medium leading-[1.06] sm:text-[44px] lg:text-[56px] xl:text-[75px]">
              FAQs
            </h2>
          </div>
          <ul className="flex flex-col gap-y-6">
            {faqItems.map((item, index) => (
              <li
                key={index}
                className={`rounded-[10px] border-[1px] border-[#EAEDF0] bg-white px-7 py-[30px] ${
                  openIndex === index ? 'bg-gray-100' : ''
                }`}
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <h5 className="text-xl font-medium">{item.question}</h5>
                  <div className="flex items-center justify-center">
                    {openIndex === index ? (
                      <img
                        src="/images/plus-white.svg"
                        alt="minus"
                        className="w-5 h-5"
                      />
                    ) : (
                      <img
                        src="/images/plus.svg"
                        alt="plus"
                        className="w-5 h-5"
                      />
                    )}
                  </div>
                </div>
                {openIndex === index && (
                    <div className="text-lg text-[#2C2C2C] mt-4">
                      <p className=" w-96 break-words">{item.answer}</p>
                    </div>
                  )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
