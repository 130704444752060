import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_VERSION_2 } from '../../constant';
import Alert from '../../components/Alerts/Alert';

const CreatePayment = ({ agencyId }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [paymentData, setPaymentData] = useState({
    amount: '',
    image: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('agencyId', agencyId);
      formData.append('amount', paymentData.amount);
      formData.append('image', paymentData.image);
      console.log(paymentData)

      const response = await axios.post(`${API_VERSION_2}/payments`, formData, {
        headers: {
          'Authorization': `Bearer ${user.user.authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setAlertData({ message: response.data.message || 'Success!', status: 'success' });
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error adding payment:', error);
      setAlertData({ message: 'An error occurred while adding a payment', status: 'error' });
      setLoading(false);
    }
  };

  return (
    <>
      <Alert
        message={alertData?.message}
        status={alertData?.status}
        onClose={() => setAlertData(null)}
      />
      <form onSubmit={handleSubmit} className="p-4 md:p-5 flex flex-col gap-5">
        {/* Amount Input */}
        <p>
         Please upload proof of payment
        </p>
        <input
          type="number"
          name="amount"
          placeholder="Amount"
          className="shadow-sm border border-gray-300 sm:text-sm rounded-lg block w-full p-2.5"
          onChange={handleChange}
          required
        />

        {/* File Upload Input */}
        <input
              type="file"
              name="image"
              onChange={handleChange}
              accept="image/*"
              className="border border-gray-300 text-sm rounded-lg block w-full p-2.5"
              required
            />

        {/* Submit Button */}
        {loading ? (
          <button disabled type="button" className="loading-button">
            Loading...
          </button>
        ) : (
          <button
            type="submit"
            className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none flex items-center gap-3 mb-2 primaryBtn"
          >
            Add A Payment
          </button>
        )}
      </form>
    </>
  );
};

export default CreatePayment;
